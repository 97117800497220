<template>
	<div class="clientInfo">
		<div ref="clientInfo" class="clientInfo-container">
			<img src="@/assets/yrylogo.png" alt="" />
			<div style="overflow: auto; padding: 0 0 10px 0" class="test-1">
				<div style="padding: 10px">
					<el-card class="box-card">
						<div class="desc" :class="{
							activeColor: !(
								item.feedbackStateName == '已反馈'
							),
						}">
							{{ item.feedbackStateName }}
						</div>
						<p style="
								font-size: 22px;
								font-weight: 800;
								color: #23924f;
								margin: 0 0 20px 0;
								cursor: pointer;
							">
							{{ item.clientNameInput || item.clientName }}
						</p>

						<div style="font-weight: 800">客户简介</div>
						<div class="info" v-html="fn(item.recommendedzReason || '')"></div>
						<div style="font-weight: 800">客户需求</div>
						<div class="info" v-html="fn(item.clientRequirement || '')"></div>
						<div style="font-weight: 800">注意事项</div>
						<div class="info" v-html="fn(item.noticeMatter || '')"></div>
						<div style="font-weight: 800">咨询问题</div>
						<div class="info" v-html="fn(item.askQuestions || '')"></div>
						<div style="font-weight: 800">备注</div>
						<div class="info" v-html="fn(item.remark || '')"></div>
						<div style="font-weight: 800" v-if="item.inquiryAttachment &&
							item.inquiryAttachment.length
							">
							附件
						</div>

						<el-upload :headers="{
							token: $store.state.userInfo.token,
						}" :on-preview="handelOnChange" class="upload-demo" name="files"
							:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" multiple :limit="3"
							:file-list="item.inquiryAttachment">
						</el-upload>
						<div style="display: flex" v-if="!mobileInfo">
							<div style="flex: 1" v-if="item.audio">
								<div style="font-weight: 800">音频信息</div>
								<div name="showVideo" style="float: left">
									<div v-for="(
											item, index
										) in item.audio.split(',')" :key="index" id="video0" style="float: left; margin: 10px">
										<audio preload="metadata" controls="" width="320" height="240">
											<source name="audioUrl" :src="item" type="audio/mpeg" />
											您的浏览器不支持 audio 元素。
										</audio>
									</div>
								</div>
							</div>
							<div style="flex: 1" v-if="item.video">
								<div style="font-weight: 800">视频信息</div>
								<div name="showVideo" style="float: left">
									<div v-for="(
											item, index
										) in item.video.split(',')" :key="index" id="video0" style="float: left; margin: 10px">
										<video preload="metadata" width="240" height="100" controls="">
											<source preload="metadata" name="videoUrl" :src="item" type="video/mp4" />
											您的浏览器不支持Video标签。
										</video>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<div v-if="item.audio">
								<div style="font-weight: 800">音频信息</div>
								<div name="showVideo" style="float: left">
									<div v-for="(
											item, index
										) in item.audio.split(',')" :key="index" id="video0" style="float: left; margin: 10px 0">
										<audio preload="metadata" controls="" width="320" height="240">
											<source name="audioUrl" :src="item" type="audio/mpeg" />
											您的浏览器不支持 audio 元素。
										</audio>
									</div>
								</div>
							</div>
							<div v-if="item.video">
								<div style="font-weight: 800">视频信息</div>
								<div name="showVideo" style="float: left">
									<div v-for="(
											item, index
										) in item.video.split(',')" :key="index" id="video0" style="float: left; margin: 10px">
										<video preload="metadata" width="240" height="100" controls="">
											<source preload="metadata" name="videoUrl" :src="item" type="video/mp4" />
											您的浏览器不支持Video标签。
										</video>
									</div>
								</div>
							</div>
						</div>
						<div @click="isShowDialogVisible(item)" v-if="item.clientId" style="
								margin-bottom: 30px;
								margin-top: 20px;
								font-weight: 800;
								font-size: 20px;
								color: red;
								cursor: pointer;
							">
							点击查看客户详情
						</div>

						<div v-if="!mobileInfo" style="display: flex; margin-bottom: 30px">
							<div style="margin-right: 40px">
								<span style="font-weight: 800">方案经理</span>
								{{ item.productManagerName || '' }}
							</div>
							<div>
								<span style="font-weight: 800">联系电话</span>
								{{ item.productManagerPhone || '' }}
							</div>
						</div>
						<div v-else style="margin-bottom: 30px">
							<div style="margin-right: 40px; margin-bottom: 20px">
								<span style="font-weight: 800">方案经理</span>
								{{ item.productManagerName || '' }}
							</div>
							<div>
								<span style="font-weight: 800">联系电话</span>
								{{ item.productManagerPhone || '' }}
							</div>
						</div>
						<div style="font-weight: 800" v-if="item.enquiryResultsName">
							询单结果
						</div>
						<div class="info" v-if="item.enquiryResultsName" v-html="fn(item.enquiryResultsName || '')"></div>
						<div v-if="item.feedbackInfo">
							<div style="font-weight: 800">
								我反馈给悦融易的信息
							</div>
							<div class="info" v-html="fn(item.feedbackInfo || '')"></div>
						</div>
						<div style="padding: 15px 0 0 0; text-align: left">
							<div v-if="item.feedbackStateName == '待反馈'" class="replyinfo">
								<p>请输入快捷回复信息</p>
								<el-radio-group style="padding: 15px 0px" v-model="replyinfo.enquiryResultsId">
									<el-radio v-for="(
											item, index
										) in EnquiryResultsList" :key="index" :label="item.value">{{ item.label }}</el-radio>
								</el-radio-group>
								<el-input type="textarea" :rows="6" v-model="replyinfo.feedbackInfo" placeholder="请输入回复内容">
								</el-input>
								<div class="replyinfo-btn">
									<el-button @click="postClientInquiryReply()" type="primary">回复</el-button>
								</div>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</div>
		<div v-if="previewImg" @click="previewImg = false" style="
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				z-index: 9;
			">
			<div style="
					position: fixed;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0.5;
					background: #000;
					margin: auto;
				"></div>
			<div style="
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				">
				<img style="
						transform: scale(1) rotate(0deg);
						margin-left: 0px;
						margin-top: 0px;
						max-height: 100%;
						max-width: 100%;
					" :src="imgUrl" alt="" />
			</div>
		</div>
		<notice @close="() => {
			centerDialogVisible = false;
			changeProtocolIdentification();
		}
			" :centerDialogVisible.sync="centerDialogVisible" />
		<publicInfoDrawer v-if="!mobileInfo" :allData="allData" :privacyType="privacyType"
			:dialogVisible.sync="publicDialogVisible" :paramsId="paramsId" @closeVisible="publicDialogVisible = false" />
		<mobileInfoDrawer v-else :allData="allData" :privacyType="privacyType" :dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId" @closeVisible="publicDialogVisible = false" />
	</div>
</template>

<script>
import notice from '../index/model/notice.vue';
import mobileInfoDrawer from '@/components/mobileInfoDrawer/mobileInfoDrawer.vue';
import { changeProtocolIdentification } from '@/api/index.js';
import { myMixin } from '@/mixins';

import { mapGetters } from 'vuex';
import {
	shortLinkLogin,
	clientInquiryReply,
	readClientInquiry,
	itemByType,
} from '@/api/index.js';
export default {
	name: 'currentinquiry',
	mixins: [myMixin],

	data() {
		return {
			previewImg: false,
			imgUrl: '',
			replyinfo: {
				inquiryId: '',
				feedbackInfo: '',
				enquiryResultsId: '',
			},
			EnquiryResultsList: [],
			item: {},
			inquiryId: '',
			centerDialogVisible: false,
			mobileInfo: false,
		};
	},
	components: {
		notice,
		mobileInfoDrawer,
	},
	created() {
		this.shortLinkLogin({ shortLink: this.$route.query.token });
		this.getEnquiryResults();
		this.browserRedirect();
	},
	methods: {
		handelOnChange(res) {
			!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(res.url)
				? (location.href =
					this.$baseUrl +
					'api/file/fileDownloadCommon.do?path=' +
					res.url)
				: (this.previewImg = true);
			this.imgUrl = res.url;
		},
		browserRedirect() {
			var curURL = window.location.href;
			var sUserAgent = navigator.userAgent.toLowerCase();

			var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
			var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
			var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
			var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
			var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
			var bIsAndroid = sUserAgent.match(/android/i) == 'android';
			var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
			var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';

			if (
				bIsIpad ||
				bIsIphoneOs ||
				bIsMidp ||
				bIsUc7 ||
				bIsUc ||
				bIsAndroid ||
				bIsCE ||
				bIsWM
			) {
				this.mobileInfo = true;
			} else {
				this.mobileInfo = false;
			}
		},
		async changeProtocolIdentification() {
			await changeProtocolIdentification();
		},
		isShowDialogVisible(item) {
			if (item.clientId) {
				this.showDialogVisible(item, true, true);
			}
		},
		async getEnquiryResults() {
			const res = await itemByType({
				dictionaryType: 'EnquiryResults',
			});
			if (res.returncode === 0) {
				this.EnquiryResultsList = res.data.map((item) => ({
					value: Number(item.itemValue),
					label: item.itemName,
				}));
			}
		},
		async postClientInquiryReply() {
			if (!this.replyinfo.enquiryResultsId) {
				return this.$message({
					message: '请选择可以做/需沟通/做不了',
					type: 'warning',
				});
			}
			if (!this.replyinfo.feedbackInfo) {
				return this.$message({
					message: '请输入回复内容',
					type: 'warning',
				});
			}
			const res = await clientInquiryReply(this.replyinfo);
			if (res.returncode == 0) {
				const d = this.EnquiryResultsList.find(
					(item) => item.value == this.replyinfo.enquiryResultsId
				);
				this.item.feedbackStateName = '已反馈';
				this.item.enquiryResultsName = d.label;
				this.item.feedbackInfo = this.replyinfo.feedbackInfo;
				this.replyinfo.feedbackInfo = '';
				this.replyinfo.enquiryResultsId = '';
				this.$message({
					message: '回复成功',
					type: 'success',
				});
			}
		},
		async getClientInquiryList() {
			const res = await readClientInquiry({ inquiryId: this.inquiryId });
			if (res.inquiryAttachment) {
				res.inquiryAttachment = JSON.parse(res.inquiryAttachment).map(
					(item) => ({
						name: item.fileName,
						url: !/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.ossUrl)
							? item.filePath
							: item.ossUrl,
					})
				);
			} else {
				res.inquiryAttachment = [];
			}
			this.item = res;

			this.replyinfo.feedbackInfo = res.feedbackInfo;
			this.replyinfo.enquiryResultsId = res.enquiryResultsId;
			this.replyinfo.inquiryId = res.inquiryId;
		},
		async shortLinkLogin(data) {
			const res = await shortLinkLogin(data);
			if (res.rtnCode === 0) {
				this.inquiryId = res.inquiryId;
				localStorage.setItem('userInfo', JSON.stringify(res.tokenVO));
				this.$store.commit('setUserInfo', res.tokenVO);
				this.centerDialogVisible = res.tokenVO.userVo
					.protocolIdentification
					? false
					: true;
				this.getClientInquiryList();
			}
		},

		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
	},
	computed: {
		...mapGetters(['getUserInfo']),
	},
};
</script>

<style lang="scss" scoped>
@media screen and (min-width:1200px) {
	.mediabox {
		padding: 0 100px;
	}

	.el-card__body {
		padding: 20px !important;
	}

	.composing {
		display: flex;
	}

	.go-feedback {
		display: none;
	}

	.running-info>div {
		width: 50% !important;
	}

	.dynamic {
		width: 65% !important;
	}
}

@media screen and (max-width: 1200px) {
	.dynamic {
		width: 100% !important;
	}

	.replyinfo {
		width: 100% !important;
	}
}

.running-info>div {
	width: 100%;
}

.clientInfo textarea {
	resize: none;
}

.diytable_weight {
	width: 100% !important;
}
</style>

<style lang="scss" scoped>
::v-deep .diytable tbody tr[style] td {
	text-align: center !important;
}

.diytable_weight {
	width: 65%;
}

.diytable_box {
	.el-table--border {
		border-top: none;
	}

	.el-table::before {
		width: 0%;
	}

	.el-table--border::after {
		width: 0%;
	}
}

::v-deep .diytable_weight tr:nth-child(9) td:first-child {
	font-weight: 800;
}

::v-deep .diytable_weight tr:nth-child(17) td:first-child {
	font-weight: 800;
}

::v-deep .diytable_weight tr:nth-child(21) td:first-child {
	font-weight: 800;
}

::v-deep .el-textarea {}

.clientInfo {
	width: 100%;
	height: 100%;
	background: #f0f2f5 url(~@/assets/background.svg);

	.clientInfo-container>img {
		margin: 0 auto;
		height: 44px;
		margin-top: 20px;
		margin-bottom: 20px;
		width: 225px;
	}
}

/* reset element-ui css */
.clientInfo-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	background: #f0f2f5 url(~@/assets/background.svg);
	transition: 2s;
}

.clientInfo-text {
	color: #000;
	font-weight: 800;
	cursor: pointer;
	font-size: 16px;
	padding-left: 10px;
	margin-bottom: 15px;
}

.box-card {
	margin-bottom: 10px;
}

::v-deep .demo-drawer__footer {
	width: 100%;
	padding-bottom: 20px;
	display: flex;
	justify-content: center;
}

.form1 ::v-deep .el-form-item {
	margin-bottom: 0;
	padding-left: 20px;
}

.t1,
.t2,
.t3 {
	color: #3e31ff;
	font-weight: 800;
	margin: 10px 0;
}

.t1 {
	font-size: 20px;
}

.t2 {
	font-size: 18px;
	text-indent: 10px;
}

.t3 {
	font-size: 16px;
	text-indent: 20px;
}

.text-item {
	text-indent: 40px;
	margin: 20px 0;
}

::v-deep .el-input-number__increase {
	display: none;
}

::v-deep .el-input-number__decrease {
	display: none;
}

::v-deep .el-input-number input {
	text-align: left !important;
}

.info {
	line-height: 22px;
	margin: 10px 15px 20px 20px;
	text-indent: 0px;
	display: flex;
}

::v-deep .el-card__body {
	padding: 30px;
}

::v-deep .el-upload-list__item:first-child {
	margin-top: 5px !important;
}

.go-feedback {
	position: fixed;
	width: 50px;
	height: 50px;
	background: #fff;
	border-radius: 50%;
	left: 20px;
	bottom: 20px;
	line-height: 50px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	text-align: center;
	opacity: 0.8;
}

.diytable_box {
	border-right: 1px solid #ebeef5;
}

.inquiry {
	width: 100%;
	background: #fff;
	overflow: auto;
	position: relative;
}

.info {
	line-height: 20px;
	margin: 10px 15px 20px 15px;
}

.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}

.activeColor {
	background: #f64c4c;
}

.box-card {
	position: relative;
}

.replyinfo {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 50%;

	p {
		font-size: 14px;
		font-weight: 800;
		margin-bottom: 10px;
	}

	.replyinfo-btn {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}
}

.test-1 {
	width: 95%;
	margin: 0 auto;
}

::v-deep .el-upload-list__item>label {
	display: none !important;
}

::v-deep .el-upload-list__item>i {
	display: none !important;
}
</style>
